export enum DatePeriod {
  PAST_90_DAYS = 'PAST_90_DAYS',
  PAST_30_DAYS = 'PAST_30_DAYS',
  PAST_365_DAYS = 'PAST_365_DAYS',
}

export const DatePeriodDisplay: Record<DatePeriod, string> = {
  [DatePeriod.PAST_30_DAYS]: 'Past 30 Days',
  [DatePeriod.PAST_90_DAYS]: 'Past 90 Days',
  [DatePeriod.PAST_365_DAYS]: 'Past 365 Days',
};

export type ValidDatePeriod = keyof typeof DatePeriod;

export type ValidObject = Record<string, unknown>;
