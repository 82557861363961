import React from 'react';
import { useHistory } from 'react-router';
import cx from 'classnames';
import { periodDisplayLanguage, useAuth, useDashboard, useOrganization, useSocket } from 'containers';
import { format } from 'date-fns';
import {
  DonutChart,
  Navigation,
  DatePeriodSelect,
  Toolbar,
  Card,
  StandaloneFigure,
  StackFigure,
  TasksSnapshot,
  Hero,
  GroupsSelect,
  Spinner,
  OrgMembersSelect,
  DeepSelect,
} from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { AnalysisStatusDisplay, DashboardQueryParams, DatePeriodDisplay, Group, ROUTES } from '@interfaces';
import { Grid } from '@react-css/grid';
import { _, initI18n, toAbbreviatedUSD } from 'utils';
import { downloadDashboardTasksCSV } from 'Sync';

type DashboardProps = RouteComponentProps<DashboardQueryParams>;

export const Dashboard: React.FC<DashboardProps> = () => {
  const [groups, setGroups] = React.useState<Group[] | undefined>();
  const [i18n, setI18n] = React.useState<Record<string, string>>({});
  const {
    selectedPeriod,
    setPeriod,
    selectedGroup,
    setSelectedGroup,
    selectedOwner,
    setSelectedOwner,
    selectedFacility,
    selectedEquipment,
    setSelectedFacility,
    periodOrigins,
    data,
    loading,
    setLoading,
    fetchDashboardData,
    setData,
    setTasks,
  } = useDashboard();
  const { send: dispatch, addMessageHandlers, close: closeSocket, isConnected } = useSocket();
  const { isAdmin, logout, i18n: i18nOn, token } = useAuth();
  const { setOrganization, users } = useOrganization();
  const history = useHistory();
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    if (i18nOn) {
      initI18n(strings => {
        setI18n(strings);
      });
    }
  }, [i18nOn]);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    const unsubscribe = history.listen(location => {
      const isDashboardRoute = location.pathname.includes(ROUTES.DASHBOARD);

      if (!isDashboardRoute) {
        console.warn('[HOTFIX, PLEASE REMOVE]: Navigating away from dashboard...closing socket');
        return closeSocket();
      }
    });

    return () => unsubscribe();
  }, [history, closeSocket]);

  // @NOTE we need to re-examine this hook's dependencies. it doesn't make sense to run this every time selectedPeriod changes
  React.useEffect(() => {
    if (!isConnected || !isMounted.current) return;

    return addMessageHandlers({
      LIST_GROUPS: ({ groups }) => {
        setGroups(groups);
        setLoading(false);
      },
      GET_ORGANIZATION: ({ organization }) => setOrganization(organization),
      // GET_FACILITIES: ({ facilities }) => setFacilities(facilities),
      // GET_EQUIPMENTS: ({ equipments }) => setEquipment(equipments),
      // GET_DASHBOARD_DATA: ({ data }) => {
      //   setData(data);
      //   setLoading(false);
      // },
      // GET_DASHBOARD_TASKS: ({ tasks }) => {
      //   setTasks(tasks);
      //   setLoading(false);
      // },
      // GET_DASHBOARD_TASKS_CSV: ({ csv }) => {
      //   const blob = new Blob([csv], { type: 'text/csv' });
      //   const objectUrl = window.URL.createObjectURL(blob);
      //   const a = document.createElement('a');
      //   a.href = objectUrl;
      //   a.download = `EasyRCA_Tasks_${new Date().toISOString().split('T')[0]}.csv`;
      //   document.body.appendChild(a);
      //   a.click();
      //   setTimeout(() => {
      //     a.remove();
      //     window.URL.revokeObjectURL(objectUrl);
      //   }, 1500);
      // },
      EXPIRED_USER: () => {
        logout();
        closeSocket();
        window.location.replace(`${process.env.REACT_APP_EXPIRED_REDIRECT_URL}`); // See index.html
      },
      SIGN_OUT: () => {
        logout();
        closeSocket();
        window.location.replace('/');
      },
    });
  }, [addMessageHandlers, setData, setTasks, closeSocket, setLoading, logout, isConnected, setOrganization]);

  React.useEffect(() => {
    if (isConnected && isMounted.current) {
      // if (!equipment) {
      //   dispatch({ type: 'GET_EQUIPMENTS' });
      // }

      if (Object.values(users).length < 1) {
        dispatch({ type: 'GET_ORGANIZATION' });
      }

      if (!groups) {
        dispatch({ type: 'LIST_GROUPS' });
      }

      // if (!facilities) {
      //   dispatch({ type: 'GET_FACILITIES' });
      // }
    }

    return;
  }, [dispatch, isConnected, groups, /*facilities, equipment,*/ selectedPeriod.interval, setLoading, users]);

  React.useEffect(() => {
    if (!token) {
      return;
    }
    // setLoading(true);
    // dispatch({
    //   type: 'GET_DASHBOARD_DATA',
    //   periodMonths: selectedPeriod.interval,
    //   groupUuid: selectedGroup,
    //   owner: selectedOwner,
    //   facility: selectedFacility,
    //   // equipmentTypeUuid: selectedEquipment,
    // });
    fetchDashboardData({
      token,
      groupUuid: selectedGroup,
      owner: selectedOwner,
      facility: selectedFacility,
      equipment: selectedEquipment,
      periodMonths: selectedPeriod.interval,
    });
  }, [
    selectedGroup,
    selectedOwner,
    selectedFacility,
    /*selectedFacility, selectedEquipment,*/ dispatch,
    selectedPeriod.interval,
    token,
    // setLoading,
    // setSelectedFacility,
    // setSelectedOwner,
    // isConnected,
  ]);

  const analysesByStatus = React.useMemo(() => {
    if (!data) return [];
    if (!data.all) return [];
    if (!data.all.trees) return [];

    if (data.all.trees) {
      return [
        {
          label: AnalysisStatusDisplay.PENDING,
          value: data?.all.trees?.byStatus.PENDING,
        },
        {
          label: AnalysisStatusDisplay.IN_PROCESS,
          value: data?.all.trees?.byStatus.IN_PROCESS,
        },
        {
          label: AnalysisStatusDisplay.REVIEW,
          value: data?.all.trees?.byStatus.REVIEW,
        },
      ];
    }

    return [];
  }, [data]);

  const totalActiveTrees = React.useMemo(() => {
    if (!data || !data.all || !data.all.trees) return 0;

    const { byStatus } = data.all.trees;
    const { REVIEW, IN_PROCESS, PENDING } = byStatus;
    return (REVIEW || 0) + (IN_PROCESS || 0) + (PENDING || 0);

    return 0;
  }, [data]);
  // const facilitySelectValue = React.useMemo(() => {
  //   if (facilities && selectedFacility) {
  //     const facilityObj = facilities.find(f => f.facilityUuid === selectedFacility);

  //     if (!facilityObj) {
  //       return;
  //     }

  //     return {
  //       value: selectedFacility,
  //       // @ts-ignore
  //       totalSites: facilityObj?.sites.length || 0,
  //       label: facilityObj?.name,
  //     };
  //   }

  //   return undefined;
  // }, [facilities, selectedFacility]);
  // const equipmentSelectValue = React.useMemo(() => {
  //   if (equipment && selectedEquipment) {
  //     const equipmentObj = equipment.find(e => e.equipmentUuid === selectedEquipment);

  //     if (!equipmentObj) {
  //       return;
  //     }

  //     return {
  //       value: selectedEquipment,
  //       // @ts-ignore
  //       totalClasses: equipmentObj?.classes.length || 0,
  //       label: equipmentObj?.name,
  //     };
  //   }

  //   return undefined;
  // }, [equipment, selectedEquipment]);
  const selectedOwnerObject = Object.values(users).find(u => u.username === selectedOwner);

  const handleDownloadTasksCSV = async () => {
    try {
      await downloadDashboardTasksCSV({ token });
    } catch (error) {
      console.error(error);
    }
  };

  const ownersSelectValue = selectedOwnerObject
    ? {
        value: selectedOwner,
        userId: selectedOwnerObject.id,
        label: selectedOwnerObject.username,
      }
    : undefined;

  const groupsSelectValue =
    groups && selectedGroup
      ? {
          value: selectedGroup,
          label: groups.find(g => g.groupUuid === selectedGroup)!.name,
        }
      : undefined;

  // const openVerificationsFlyout = ({ set }) =>
  //   history.push(`/dashboard/tasks/verifications?set=${set}`);

  // const openCorrectiveActionsFlyout = ({ set }) =>
  //   history.push(`/dashboard/tasks/corrective-actions?set=${set}`);

  const Filters = ({ isLoading = false }: { isLoading?: boolean }) => (
    <div className="flex items-stretch">
      <div className="w-10 flex items-center">{isLoading && <Spinner size={24} message="" />}</div>
      <div className="flex w-100 items-center justify-start">
        <div className="w-25 mr2">
          <OrgMembersSelect
            data={Object.values(users)}
            value={ownersSelectValue}
            placeholder={_(i18n, 'Owner')}
            isClearable
            isMulti={false}
            onChange={selected => {
              if (!selected) {
                return setSelectedOwner(null);
              } else {
                return setSelectedOwner(selected.value);
              }
            }}
          />
          {/* <EquipmentSelect
            isClearable
            placeholder={_(i18n, 'Equipment')}
            data={equipment}
            value={equipmentSelectValue}
            onChange={selected => {
              if (!selected) {
                return setSelectedEquipment('');
              } else {
                return setSelectedEquipment(selected.value);
              }
            }}
          /> */}
        </div>
        <div className="w-33 mr2">
          <DeepSelect
            type="facilities"
            token={token}
            isClearable
            placeholder={_(i18n, 'Facility')}
            value={
              selectedFacility
                ? {
                    label: selectedFacility.namePath.join(' '),
                    value: selectedFacility.path.join('-'),
                    // value: selectedFacility.uuidPath.join('-'),
                    object: selectedFacility,
                  }
                : null
            }
            onChange={selected => {
              if (!selected) {
                return setSelectedFacility(null);
              } else {
                return setSelectedFacility(selected.object);
              }
            }}
          />
        </div>
        <div className="w-25 mr2">
          <GroupsSelect
            isMulti={false}
            isClearable
            placeholder={_(i18n, 'Group')}
            data={groups}
            value={groupsSelectValue}
            onChange={selected => {
              if (!selected) {
                return setSelectedGroup(null);
              } else {
                // @ts-ignore
                return setSelectedGroup(selected.value);
              }
            }}
          />
        </div>
        <div className="w-25">
          <DatePeriodSelect
            isClearable={false}
            onChange={selected => {
              if (selected?.value)
                return setPeriod({
                  from: periodOrigins[selected?.value as string],
                  identifier: selected.value,
                });
            }}
            value={{
              value: selectedPeriod.identifier,
              label: DatePeriodDisplay[selectedPeriod.identifier],
            }}
          />
        </div>
      </div>
    </div>
  );

  if (data) {
    return (
      <>
        <Navigation isAdmin={isAdmin} onLogout={() => dispatch({ type: 'SIGN_OUT' })} />
        <Hero pageTitle="Dashboard" style={{ marginTop: 48 }} />
        <div className="bg-white ph4">
          <div className="ph4">
            <div className="relative">
              <Toolbar
                isLoading={loading}
                subtitle={`${format(selectedPeriod.from, 'MMM do, R')} – Today`}
                title="RCA Program"
                renderItems={Filters}
              />
              <div className={cx({ 'no-pointer-events': loading })}>
                <section
                  className={cx('analyses', {
                    dimIn: !loading,
                    dimOut: loading,
                  })}
                  style={{
                    paddingBottom: '3rem',
                  }}
                >
                  <Grid columns="30% 28% 41%" gridGap="1.2em">
                    <Grid.Item>
                      <Grid rows="10px auto" rowGap="2em" justifyContentStretch alignItemsStart>
                        <Grid.Item>
                          <h1 className="fs-xl fw-semibold ma0 pv1 i18n">Analyses</h1>
                        </Grid.Item>
                        <Grid.Item>
                          <Card title="Currently Active" subtitle={`${totalActiveTrees} Total`} minHeight="228px">
                            <Card.Content className="flex1 ">
                              {totalActiveTrees > 0 ? (
                                <DonutChart width={400} legend data={analysesByStatus} />
                              ) : (
                                <div className="flex flex-column items-center justify-end">
                                  <span className="w-100 f5">No active analyses found.</span>
                                </div>
                              )}
                            </Card.Content>
                          </Card>
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                    <Grid.Item alignSelfEnd>
                      <Grid justifyContentStretch gap="1.2em" alignItemsStart>
                        <Grid.Item>
                          <StandaloneFigure
                            label="Created"
                            currentValue={data?.current.trees.created}
                            previousValue={data?.previous.trees.created}
                            helpText={`Analyses created in past ${
                              periodDisplayLanguage[selectedPeriod.identifier]
                            } and change from previous ${periodDisplayLanguage[selectedPeriod.identifier]}`}
                          />
                        </Grid.Item>
                        <Grid.Item>
                          <StandaloneFigure
                            label="Updated"
                            currentValue={data?.current.trees.updated}
                            previousValue={data?.previous.trees.updated}
                            helpText={`Analyses updated in past ${
                              periodDisplayLanguage[selectedPeriod.identifier]
                            } and change from previous ${periodDisplayLanguage[selectedPeriod.identifier]}`}
                          />
                        </Grid.Item>
                        <Grid.Item>
                          <StandaloneFigure
                            label="Completed"
                            currentValue={data?.current.trees.completed}
                            previousValue={data?.previous.trees.completed}
                            helpText={`Analyses completed in past ${
                              periodDisplayLanguage[selectedPeriod.identifier]
                            } and change from previous ${periodDisplayLanguage[selectedPeriod.identifier]}`}
                          />
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </section>
                <section
                  className={cx('root-causes-and-failures', {
                    dimIn: !loading,
                    dimOut: loading,
                  })}
                >
                  <Grid columns="30% 70%" gridGap="1.2em">
                    <Grid rows="51px auto" rowGap="1em" justifyContentStretch alignItemsStart>
                      <Grid.Item>
                        <h1 className="i18n fs-xl fw-semibold ma0 pv1">Cost of Failures</h1>
                        <h3 className="fw-light fs-s ma0 pv1">
                          {data?.current.trees.impactedFacilities} Impacted Facilities
                        </h3>
                      </Grid.Item>
                      <Grid.Item alignSelfStart>
                        <StackFigure
                          firstRowLabel="Total Cost"
                          firstRowValue={toAbbreviatedUSD(data?.current.trees.totalCost)}
                          secondRowLabel="Previous Period"
                          secondRowValue={toAbbreviatedUSD(data?.previous.trees.totalCost)}
                          firstRowHelpText={`
                      Annualized financial costs associated with all analyses created in past ${
                        periodDisplayLanguage[selectedPeriod.identifier]
                      } (or previous ${periodDisplayLanguage[selectedPeriod.identifier]})
                      `}
                        />
                      </Grid.Item>
                    </Grid>
                    <Grid rows="51px auto" rowGap="1em" justifyContentStretch alignItemsStart>
                      <Grid.Item>
                        <h1 className="i18n fs-xl fw-semibold ma0 pv1">Tasks</h1>
                        <button className="unstyled underline fw-light ph0 pv1 fs-xs" onClick={handleDownloadTasksCSV}>
                          <span className="i18n">Download Task CSV</span>
                        </button>
                      </Grid.Item>
                      <Grid.Item>
                        <TasksSnapshot
                          correctiveActionsWeeklyAvg={data?.current.tasks.correctiveAction.completedByWeek}
                          verificationsWeeklyAvg={data?.current.tasks.verification.completedByWeek}
                          totalCorrectiveActions={data?.current.tasks.correctiveAction.created}
                          totalVerifications={data?.current.tasks.verification.created}
                          overdueCorrectiveActions={data?.current.tasks.correctiveAction.overdue}
                          overdueVerifications={data?.current.tasks.verification.overdue}
                          upcomingVerifications={data?.current.tasks.verification.upcoming}
                          upcomingCorrectiveActions={data?.current.tasks.correctiveAction.upcoming}
                        />
                      </Grid.Item>
                    </Grid>
                  </Grid>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};
