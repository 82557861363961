import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { SubtractAlt20, EventSchedule20, ListChecked20, Edit20 } from '@carbon/icons-react';
import { parse, formatDistance, format } from 'date-fns';
import { OrgMembersSelect } from 'components';
import { Select, Input, Textarea } from './TextInputs';
import { Button } from './Buttons';
import { highlight } from './Blade';
import { Avatar } from './Nav';
import ToggleButton from './ToggleButton';
import { User } from './@interfaces';

const parseOptions = List(['yyyy-MM-dd', 'MM-dd-yyyy', 'yyyy/MM/dd', 'MM/dd/yyyy']);

const tryParse = (s, opt) => {
  try {
    return parse(s, opt, new Date());
  } catch (err) {
    return null;
  }
};

const tryAllParseOptions = s => {
  return parseOptions
    .map(opt => tryParse(s, opt))
    .filter(x => x)
    .first();
};

const overdue = deadline => {
  return deadline < Date.now();
};

const distanceInTime = epochMillis => {
  const now = Date.now();
  const date = new Date(epochMillis);

  const distance = formatDistance(date, now);
  return date.getTime() > now ? `${distance} from now` : `${distance} ago`;
};

export const Task = ({ state, dispatch, task, onEdit, disabled }) => {
  const [model, setModel] = useState(Map());
  const treeUuid = state.getIn(['tree', 'uuid']);
  const taskUuid = task.get('taskUuid');
  const title = task.get('title');
  const description = task.get('description');
  const deadline = task.get('deadline');
  const doers = task.get('doers');
  const completedAt = task.get('completedAt');
  const completedBy = task.get('completedBy');
  const nodeUuid = task.get('nodeUuid');
  const taskType = task.get('taskType');
  const isOverdue = overdue(deadline);
  const time = distanceInTime(deadline);
  const completedTime = completedAt ? distanceInTime(completedAt) : '';

  return (
    <div
      className={classNames('flex justify-between items-center w-100 mb1 bg-black pa3 relative')}
      onMouseEnter={() => {
        setModel(model.set('hover', true));
        highlight(dispatch, nodeUuid);
      }}
      onMouseLeave={() => setModel(model.delete('hover'))}
    >
      <div className={'flex flex-column ml2 pr4 w-100'}>
        <div className="mb3 flex items-center">
          <ListChecked20 />
          <div className="ml1 fw6 f6">{taskType === 'VERIFICATION' ? 'Verification Task' : 'Corrective Action'}</div>
        </div>
        <div className="lh-title mb2">
          <div className="fw7 mb1">{title}</div>
          <div>{description}</div>
        </div>
        <div
          className={classNames('f6 light-gray mb3 lh-title', {
            red: isOverdue && !completedAt,
          })}
        >
          {completedAt ? `Completed ${completedTime} by ${completedBy}` : time ? `Due ${time}` : deadline}
        </div>
        <div className="flex items-center">
          {doers.map(d => (
            <Avatar key={d} state={state} dispatch={dispatch} height={40} username={d} />
          ))}
        </div>
      </div>
      {disabled ? null : (
        <>
          <div
            onClick={disabled ? () => null : onEdit}
            data-tip="Edit"
            className="absolute flex bottom-0 right-2 pa2 items-center dim pointer"
          >
            <Edit20 />
          </div>
          <div
            data-tip="Delete"
            className={'red pointer dim bottom-0 right-0 pa2 absolute'}
            onClick={e => {
              if (disabled) return;

              dispatch(Map({ type: 'DELETE_TASK', treeUuid, taskUuid }));
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <SubtractAlt20 />
          </div>
        </>
      )}
      {!completedAt ? (
        <div className={'white pointer dim top-0 right-0 pa2 absolute flex items-center'} data-tip="Complete">
          <ToggleButton
            checked={false}
            onChange={() => {
              dispatch(
                Map({
                  type: 'COMPLETE_TASK',
                  treeUuid,
                  taskUuid,
                  completed: true,
                }),
              );
            }}
          />
        </div>
      ) : (
        <div className={'white pointer dim top-0 right-0 pa2 absolute flex items-center'} data-tip="Incomplete">
          <ToggleButton
            checked
            onChange={() => {
              dispatch(
                Map({
                  type: 'COMPLETE_TASK',
                  treeUuid,
                  taskUuid,
                  completed: false,
                }),
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export const Label = ({ label, onClick, onDelete }) => {
  const [model, setModel] = useState(Map());

  const truncate = (s, maxLen) => (s.length > maxLen ? `${s.substring(0, maxLen - 3)}...` : s);

  return (
    <div
      className={classNames('h2 flex justify-between items-center dib mb1 bg-light-gray pointer black')}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
      onMouseEnter={() => setModel(model.set('hover', true))}
      onMouseLeave={() => setModel(model.delete('hover'))}
    >
      <div className={'pl2 f6'}>{truncate(label, 31)}</div>
      <div
        className={'fill--black flex items-center justify-center dim pa2'}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          if (onDelete) {
            onDelete(e);
          }
        }}
      >
        <SubtractAlt20 />
      </div>
    </div>
  );
};

const taskOptions = List(['Select', 'Verification Task', 'Corrective Action']);
const defaultModel = Map({ mode: 'Node', taskType: taskOptions.first() });

export const EditTask = ({ state, dispatch, task, onEdit, onCancel, disabled }) => {
  const [model, setModel] = useState(defaultModel);
  const usersState = state.get('users', Map());
  const orgMembers = React.useMemo(() => usersState.valueSeq().toJS(), [usersState]);

  useEffect(() => {
    if (task) {
      const title = task.get('title');
      const description = task.get('description');
      const deadline = task.get('deadline');
      const doers = task.get('doers', List());
      const taskType = task.get('taskType');
      const taskUuid = task.get('taskUuid');
      setModel(m =>
        m
          .set('title', title)
          .set('description', description)
          .set('taskType', taskType === 'VERIFICATION' ? 'Verification Task' : 'Corrective Action')
          .set('doers', doers)
          .set('deadline', format(new Date(deadline), 'yyyy-MM-dd'))
          .set('taskUuid', taskUuid),
      );
    } else {
      setModel(defaultModel);
    }
  }, [setModel, task]);

  const editTask = () => {
    const title = model.get('title');
    const taskType = model.get('taskType');
    const description = model.get('description');
    const deadline = model.get('deadline');
    const doers = model.get('doers', List());
    const taskUuid = model.get('taskUuid');
    const treeUUID = state.getIn(['tree', 'uuid']);
    const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
    const node = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());
    const nodeUuid = node.get('oid');
    const nodeText = node.get('text');
    if (
      title &&
      description &&
      deadline &&
      doers &&
      doers.size > 0 &&
      taskType &&
      taskType.toLowerCase() !== 'Select'.toLowerCase()
    ) {
      if (description.length > 2000) {
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: 'Description is too long. Max 2000 characters.',
            style: 'ERROR',
          }),
        );
      } else {
        dispatch(
          Map({
            type: 'EDIT_TASK',
            title: model.get('title'),
            description: model.get('description'),
            deadline: tryAllParseOptions(model.get('deadline'), 'yyyy-LL-dd', new Date()).getTime(),
            taskType: model.get('taskType'),
            doers: List(model.get('doers')),
            treeUuid: treeUUID,
            nodeUuid: nodeUuid,
            nodeText: nodeText,
            taskUuid: taskUuid,
          }),
        );
        onEdit();
      }
    } else {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'All fields are required',
          style: 'ERROR',
        }),
      );
    }
  };

  return (
    <div>
      <div className={'mv2'}>
        <Input
          title={'Title'}
          value={model.get('title', '')}
          maxLength={80}
          onChange={e => setModel(model.set('title', e.target.value))}
          inverse
        />
      </div>
      <div className={'mv2'}>
        <Textarea
          title={'Description'}
          value={model.get('description', '')}
          onChange={e => setModel(model.set('description', e.target.value))}
          inverse
        />
      </div>
      <div className={'mv2'}>
        <Select
          title="Task Type"
          inverse
          options={taskOptions}
          value={model.get('taskType', 'Select')}
          onChange={e => setModel(model.set('taskType', e.target.value))}
        />
      </div>
      <div className="mv3">
        <div className="mb1">Responsible Parties</div>
        <div>
          {model.get('doers', List()).map(u => (
            <Label
              key={u}
              label={u}
              onDelete={() => setModel(model.update('doers', List(), ds => ds.filterNot(x => x === u)))}
            />
          ))}
        </div>
        <OrgMembersSelect
          data={orgMembers}
          isDark
          allowOptionCreation
          className="w-100 br0"
          isSearchable
          isClearable={false}
          controlShouldRenderValue={false}
          placeholder="Email address"
          onChange={(value, m) => {
            if (m.action === 'select-option' || m.action === 'create-option') {
              setModel(model.update('doers', List(), sc => sc.push(value.value)).delete('text'));
            }
          }}
        />
      </div>
      <div className="mv2">
        <Input
          title="Deadline"
          disabled={disabled}
          type="date"
          value={model.get('deadline', '')}
          inverse
          onChange={e => setModel(model.set('deadline', e.target.value))}
          placeholder={'mm/dd/yyyy'}
        />
      </div>
      <Button
        text={'Save Task'}
        disabled={disabled}
        icon={<EventSchedule20 />}
        className={'fill--white'}
        onClick={editTask}
        small
      />
      <div className="mv2">
        <Button text="Cancel" icon={<div />} small className={'w-100'} theme="secondary" onClick={onCancel} />
      </div>
    </div>
  );
};

export const AddTask = ({ state, dispatch, editingDisabled }) => {
  const taskOptions = List(['Select', 'Verification Task', 'Corrective Action']);
  const defaultModel = Map({ mode: 'Node', taskType: taskOptions.first() });
  const [model, setModel] = useState(defaultModel);
  const title = model.get('title');
  const taskType = model.get('taskType');
  const description = model.get('description');
  const deadline = model.get('deadline');
  const doers = model.get('doers', List());
  const treeUUID = state.getIn(['tree', 'uuid']);
  const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
  const node = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());
  const nodeUuid = node.get('oid');
  const nodeText = node.get('text');
  const usersState = state.get('users', List());
  const orgMembers = React.useMemo(() => usersState.valueSeq().toJS(), [usersState]);

  return (
    <div>
      <div className={'mv2'}>
        <Input
          disabled={editingDisabled}
          title={'Title'}
          value={model.get('title', '')}
          maxLength={80}
          onChange={e => setModel(model.set('title', e.target.value))}
          inverse
        />
      </div>
      <div className={'mv2'}>
        <Textarea
          disabled={editingDisabled}
          title={'Description'}
          value={model.get('description', '')}
          onChange={e => setModel(model.set('description', e.target.value))}
          inverse
        />
      </div>
      <div className={'mv2'}>
        <Select
          disabled={editingDisabled}
          title="Task Type"
          inverse
          options={taskOptions}
          value={model.get('taskType', 'Select')}
          onChange={e => setModel(model.set('taskType', e.target.value))}
        />
      </div>
      <div className="mv3">
        <div className="mb1">Responsible Parties</div>
        <div>
          {model.get('doers', List()).map(u => (
            <Label
              key={u}
              label={u}
              onDelete={() => {
                if (editingDisabled) return;

                return setModel(model.update('doers', List(), ds => ds.filterNot(x => x === u)));
              }}
            />
          ))}
        </div>
        <OrgMembersSelect
          isDisabled={editingDisabled}
          backspaceRemovesValue
          className="w-100 br0"
          data={orgMembers}
          isDark
          allowOptionCreation
          isClearable={false}
          controlShouldRenderValue={false}
          placeholder="Email address"
          onChange={(value, m) => {
            if (editingDisabled) return;

            if (m.action === 'select-option' || m.action === 'create-option') {
              return setModel(model.update('doers', List(), sc => sc.push(value.value)).delete('text'));
            }
          }}
        />
      </div>
      <div className={'mv2'}>
        <Input
          disabled={editingDisabled}
          title="Deadline"
          type={'date'}
          value={model.get('deadline', '')}
          inverse
          onChange={e => setModel(model.set('deadline', e.target.value))}
          placeholder={'mm/dd/yyyy'}
        />
      </div>
      <Button
        disabled={editingDisabled}
        text={'Save Task'}
        icon={<EventSchedule20 />}
        className={'fill--white'}
        onClick={() => {
          if (editingDisabled) return;

          if (
            title &&
            description &&
            deadline &&
            doers &&
            doers.size > 0 &&
            taskType &&
            taskType.toLowerCase() !== 'Select'.toLowerCase()
          ) {
            if (description.length > 2000) {
              dispatch(
                Map({
                  type: 'SHOW_TOAST',
                  message: 'Description is too long. Max 2000 characters.',
                  style: 'ERROR',
                }),
              );
            } else {
              dispatch(
                Map({
                  type: 'ADD_TASK',
                  title: model.get('title'),
                  description: model.get('description'),
                  deadline: tryAllParseOptions(model.get('deadline'), 'yyyy-LL-dd', new Date()).getTime(),
                  doers: List(model.get('doers')),
                  treeUuid: treeUUID,
                  taskType: model.get('taskType'),
                  nodeUuid: nodeUuid,
                  nodeText: nodeText,
                  path: path,
                }),
              );
              setModel(Map({ mode: 'Remaining Tasks' }));
            }
          } else {
            dispatch(
              Map({
                type: 'SHOW_TOAST',
                message: 'All fields are required',
                style: 'ERROR',
              }),
            );
          }
        }}
        small
      />
    </div>
  );
};
