import React, { useEffect, useReducer } from 'react';
import {
  Flow24,
  Renew32,
  RotateClockwiseAlt24,
  RotateCounterclockwiseAlt24,
  Renew24,
  ErrorOutline32,
} from '@carbon/icons-react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { TreeEditorControlsButton, TreeElements } from './TreeEditor';
import { initViewerWS } from './Sync';
import { modeToDefaultState } from './WSReducer';
import { ViewerNav } from './Nav';
import logoSrc from './svgs/logo.svg';

const ViewerControls = ({ state, dispatch }) => {
  const orientation = state.getIn(['tree', 'view', 'layout', 'rankDir']);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div
      className={'z-1 fixed bg-black-80 white pa2'}
      style={{
        top: '12rem',
        left: '1rem',
      }}
    >
      <TreeEditorControlsButton
        state={state}
        dispatch={dispatch}
        onClick={() => {
          const or = orientation === 'LR' ? 'TB' : 'LR';
          dispatch(Map({ type: 'ORIENTATION', orientation: or }));
        }}
        icon={
          orientation === 'LR' ? (
            <RotateClockwiseAlt24 />
          ) : (
            <RotateCounterclockwiseAlt24 />
          )
        }
        text={orientation === 'LR' ? 'Top-to-bottom' : 'Left-to-right'}
      />
      <TreeEditorControlsButton
        state={state}
        dispatch={dispatch}
        onClick={() => dispatch(Map({ type: 'RUN_LAYOUT' }))}
        icon={<Flow24 />}
        text={'Organize'}
      />
      <TreeEditorControlsButton
        state={state}
        dispatch={dispatch}
        onClick={() => {
          window.location.reload();
        }}
        icon={<Renew24 />}
        text={'Refresh'}
      />
    </div>
  );
};

const viewerReducer = (state, action) => {
  const type = action.get('type');
  console.log(type, state.toJS());

  switch (type) {
    case 'WS_ERROR': {
      return state.set('error', true);
    }

    case 'SYNC': {
      const tree = action.get('viewOnlyTree');
      const methodology = tree.get('methodology');
      const treeUuid = tree.get('treeUuid');
      const edges = tree.get('edges') || List();
      const elements = tree.get('elements');

      const newState = state
        .set('tree', modeToDefaultState(methodology))
        .setIn(['tree', 'uuid'], treeUuid)
        .setIn(['tree', 'edges'], edges)
        .setIn(['trees', treeUuid], tree);

      if (elements) {
        return newState.setIn(['tree', 'elements'], elements);
      } else {
        return newState;
      }
    }

    // @NOTE not sure why this is in the reducer; all of the needed info is available at the callsite (Tree.js)
    case 'RUN_LAYOUT': {
      state
        .getIn(['tree', 'view', 'cy'])
        .layout(state.getIn(['tree', 'view', 'layout']).toJS())
        .run();
      return state;
    }

    case 'SET_CY': {
      return state.setIn(['tree', 'view', 'cy'], action.get('cy'));
    }

    case 'ORIENTATION': {
      const orientation = action.get('orientation', 'TB');

      return state
        .updateIn(['tree', 'view', 'stylesheet'], ss =>
          ss
            .filterNot(x => x.get('selector') === 'edge')
            .push(
              Map({
                selector: 'edge',
                style: {
                  'curve-style': 'taxi',
                  'taxi-direction':
                    orientation === 'TB' ? 'vertical' : 'horizontal',
                },
              }),
            ),
        )
        .setIn(['tree', 'view', 'layout', 'rankDir'], orientation);
    }

    default: {
      return state;
    }
  }
};

export const LoadingWidget = ({ error, message }) => {
  const selectedMessage = message || 'Loading Analysis';
  return (
    <div
      className={classNames(
        'flex h-100 w-100 justify-center items-center flex-column',
      )}
    >
      <img
        alt={'easyrca logo'}
        className={'fixed top-1 left-1 z-1'}
        src={logoSrc}
        width={'150px'}
      />
      {!error ? (
        <div className={'flex items-center justify-center flex-column'}>
          <Renew32 className={'spin mb2 fill--blue blue'} />
          <div className={'f2 mb2'}>{selectedMessage}</div>
        </div>
      ) : (
        <div className={'flex flex-column items-center justify-center'}>
          <ErrorOutline32 className={'mb2 fill--red red'} />
          <div className={'f2 mb2'}>Unable to load this tree.</div>
          <div className={'f3 mb2'}>
            Please contact the tree owner for access.
          </div>
        </div>
      )}
    </div>
  );
};

export const TreeViewer = ({ loggedIn }) => {
  const [state, dispatch] = useReducer(viewerReducer, Map({ show: true }));
  const params = useParams();
  const treeUuid = params.uuid;

  useEffect(() => {
    if (!loggedIn && treeUuid) {
      initViewerWS(treeUuid, dispatch);
    }
  }, [treeUuid, dispatch]);

  return (
    <div className="fixed h-100 w-100">
      <ReactTooltip />
      {state.get('tree') ? (
        <div className={'h-100 w-100'}>
          <ViewerNav state={state} dispatch={dispatch} />
          <ViewerControls state={state} dispatch={dispatch} />
          <TreeElements state={state} dispatch={dispatch} />
        </div>
      ) : (
        <LoadingWidget error={state.get('error')} />
      )}
    </div>
  );
};
